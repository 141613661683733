import React, { Component } from "react"
import { Index } from "elasticlunr"
import { Link } from "gatsby"
import SearchIcon from '@material-ui/icons/Search';

// Search component
export default class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
    }
  }

  render() {
    return (
      <div style={{
				position: "relative",
			}}>
        <input 
					type="text"
					value={this.state.query}
					onChange={this.search}
					style={{
						boxSizing: "border-box",
						width: "100%",
						padding: "0 40px 0 15px",
						border: "1px solid rgba(0, 0, 0, 0.12)",
						borderRadius: "10px",
						color: "#444",
						fontSize: "20px",
						lineHeight: "50px"
					}}
					placeholder="Search..."
				/>
				<SearchIcon style={{
					position: 'absolute',
    			lineHeight: '52px',
    			height: '52px',
    			right: '15px',
					color: '#AAAAAA'
				}}/>
        <ul style={{
					position: "absolute",
    			left: "0px",
    			width: "96%",
    			textAlign: "left",
    			border: "1px solid #eee",
    			backgroundColor: "#FFF",
    			margin: "0 2%",
    			listStyle: "none"
				}}>
          {this.state.results.map(page => (
            <li
							key={page.id}
							style={{padding: '0 20px', height: '40px', lineHeight: '40px', marginBottom: '0px'}}
						>
              <Link 
								to={"/" + page.path}
								style={{
									display:'block',
									width: '100%',
									height: '100%',
									color: '#333333'
								}}
							>
								{page.title}
							</Link>
              {/*": " + page.tags.join(`,`)*/}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.searchIndex)

  search = evt => {
    const query = evt.target.value
    this.index = this.getOrCreateIndex()
    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: this.index
        .search(query, { expand: true })
        // Map over each ID and return the full document
        .map(({ ref }) => this.index.documentStore.getDoc(ref)),
    })
  }
}